import PropTypes from "prop-types";
import dynamic from 'next/dynamic';
import { Skeleton } from '@mui/material';
import Link from 'next/link';

const DCustomIcon = dynamic(() => import('./CustomIcon'),{
  loading: () => (
    <Skeleton
      animation="wave"
      variant="circle"
      width={40}
      height={40}
    />
  ),
  ssr: false
});


export default function LinkComponent({ url, textLink, tab, rel }) {
  return (
    <div>
      <Link target={tab || "_self"} href={url} >
        <a className="p-2" rel={rel || "referrer"}>{textLink || "Deja tus datos aquí"}</a>
      </Link>
    </div>
  );
}

export function LinkIcon({ url, textLink, colorLink, tab }) {
  const red = "#B1202B";

  return (
    <>
      <a target={tab || "_blank"} href={url} rel="noreferrer">
        <div className="p-2 flex">
          <p
            style={{ color: colorLink || red }}
            className="font-bold mr-2 flex items-center"
          >
            {textLink}
          </p>
          <div
            style={{ background: colorLink || red }}
            className="flex items-center text-white rounded-full"
          >
            <DCustomIcon iconName="chevronRight" viewBox="0 0 40 50" className="!w-10 !h-10"/>
          </div>
        </div>
      </a>
    </>
  );
}

export function LinkColorIcon({ url, textLink, colorLink, tab }) {
  const red = "#B1202B";
  const green = "#10312B";
  return (
    <>
      {colorLink === red ? (
        <div
          style={{
            background: colorLink,
            clipPath: "polygon(10% 28%, 100% 28%, 90% 75%, 0 75%)",
          }}
          className="flex w-fit h-36"
        >
          <a target={tab || "_blank"} href={url} rel="noreferrer">
            <div className="flex justify-center mt-11">
              <div className="w-fit font-bold flex items-center text-white mr-2 ml-12">
                <p>{textLink}</p>
              </div>
              <div className="flex items-center text-white rounded-full bg-red-dark m-3 mr-12">
                <DCustomIcon iconName="arrowRight" viewBox="0 0 50 50" className="text-2xl !w-10 !h-10"/>
              </div>
            </div>
          </a>
        </div>
      ) : (
        <div
          style={{
            background: colorLink || green,
            clipPath: "polygon(10% 28%, 100% 28%, 90% 75%, 0 75%)",
          }}
          className="flex w-fit h-36"
        >
          <a target={tab || "_blank"} href={url} rel="noreferrer">
            <div className="flex justify-center mt-11">
              <div className="w-fit font-bold flex items-center text-white mr-2 ml-12">
                <p className='text-xs sm:text-base'>{textLink}</p>
              </div>
              <div className="flex items-center text-white rounded-full bg-green-soft m-3 mr-12">
                <DCustomIcon iconName="arrowRight" viewBox="0 0 50 50" className="text-2xl !w-10 !h-10"/>
              </div>
            </div>
          </a>
        </div>
      )}
    </>
  );
}

Link.propTypes = {
  url: PropTypes.string,
  textLink: PropTypes.string,
  colorLink: PropTypes.string,
  rel: PropTypes.string,
};
